body {
  margin: 0;
  color: #262424;
  font-family: 'IM Fell DW Pica', 'IBM Plex Serif', 'Slabo 27px', 'Roboto', serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 1.4em;
}

h3 {
  font-size: 1.2em;
}

h4 {
  font-size: 1.1em;
}

.cursive {
  font-family: 'IM Fell DW Pica', 'Dancing Script', 'Seaweed Script', cursive;
  font-style: italic;
}

code,
.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.i:hover,
.left-nav-list li:hover,
table th:hover {
  cursor: pointer;
}

@media (max-width: 992px) {
  .topnav .search-form {
    display: none;
  }
}

.search-form .form-group,
.search-form .form-group input.form-control,
.search-form button {
  display: inline-block;
}

.search-page {
  margin: 40px 100px;
  min-height: 300px;
}

.search-page .search-form {
  text-align: center;
}

.product-image img,
.store-image img {
  width: 100%;
  margin-bottom: 2em;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.facet-block {
  border-left: 1px solid #999;
  margin-bottom: 2em;
}

ul.facet-list li.facet {
  padding: 0.2rem 1.25rem 1rem 0;
  border: none;
}

ul.facet-list li.facet ul.facet-value-list li.facet-value {
  padding: 0.2rem;
  border: none;
}

.facet-value.selected a {
  color: #666;
}

.topnav-logo {
  width: 4em;
}

.center-align {
  text-align: center;
}

.color-pb {
  color: #16723e;
}

.topnav {
  margin: 0 0 2.1em 0;
}

.topnav-branding {
  margin-top: 0.5em;
}

.topnav a.navbar-brand .topnav-text {
  font-size: 1.4em;
  font-family: 'Damion', cursive;
  color: #262424;
  margin-left: 0.2em;
  vertical-align: middle;
}

.topnav a.navbar-brand div,
.topnav a.navbar-brand img {
  display: inline-block;
}

.topnav .navbar-light .navbar-nav a.nav-link {
  font-size: 1.3em;
  color: rgba(0, 0, 0, 0.7);
}

.topnav .navbar-light .navbar-nav a.nav-link.active,
a,
a.active,
a.nav-link.active {
  color: #16723e;
}

.topnav .navbar-light .navbar-nav a.nav-link:hover,
a:hover,
a:active,
a:visited {
  color: #0aa94f;
}

.navbar-nav li.nav-item.nav-link {
  text-align: center;
}


.topnav .search-form {
  margin-top: 1.4em;
}

.topnav .navbar {
  margin-top: 0.2em;
}

.center-align {
  text-align: center;
}

/* a.active {
  color: #16723e !important;
} */
.spaced-icon {
  padding: 0.2em 0.4em 0.2em 0;
  display: inline-block;
}

.spaced-icon a i.fa {
  cursor: pointer;
}

.grid-item {
  margin: 1em 1em 0 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cart-item .cart-item-totals .grid-image {
  clear: both;
  float: left;
}

.cart-item-totals .cart-item-prices {
  float: right;
}

.grid-item .grid-image,
table tbody .grid-image,
.cart-item .grid-image {
  /*overflow-y:hidden;*/
  overflow: hidden;
  height: 120px;
  width: 120px;
}

.grid-item .grid-image img,
table tbody .grid-image img,
.cart-item .grid-image img,
.pb-table td img {
  width: 120px;
}

.grid-image {
  margin-top: 1em;
}

.pb-grid {
  clear: both;
}

a.view-link {
  color: #999;
}

a.view-link.active {
  color: #16723e;
}

.badge.m-2 {
  font-size: 1.6em;
  font-family: 'Roboto', serif;
}

.cart-text {
  font-family: 'Roboto', serif;
}

.cart-link .nav-link i,
.cart-link .nav-link div {
  display: inline-block;
}

.footer {
  background-color: #094825;
  color: #FFF;
  font-size: 1.2em;
}

.footer .row {
  padding: 2em 1em 2em 1em;
}

.footer .row .col-4 {
  padding: 2em;
}

.footer .footer-link,
.footer .footer-text {
  padding: 0.5em 0 0.5em 0;
}

.footer .footer-branding .company-name {
  font-family: 'Damion', cursive;
  font-size: 1.6em;
}

.footer a,
.footer a.active,
.footer a.nav-link.active {
  text-decoration: underline;
  color: #FFF;
}

.cart-store {
  margin-top: 1em;
  clear: both;
}

.shipping {
  text-align: right;
}

.cart-store-name,
.order-totals,
.order-shipping {
  font-size: 1.2em;
  font-weight: bold;
}

/* use different font for all numerals so they are readable */
.totals.order-totals,
.totals,
.product-price,
.cart-item,
.order-totals .cart-item-price,
.price-display .price-value {
  font-family: 'Roboto', serif;
}

.totals.order-totals {
  font-size: 1.3em;
}

.cart-item,
.order-totals {
  clear: both;
}

.cart-item {
  padding-top: 1.2em;
}

.cart-item-name {
  font-size: 1.1em;
  font-weight: bold;
  float: left;
  margin-right: 0.2em;
}

.cart-item-price {
  text-align: right;
}

.price-display {
  clear: both;
  float: right;
}

.price-display.last {
  margin-bottom: 1em;
}

.price-display .price-name {
  float: left;
  margin-right: 0.2em;
}

.price-display .price-value {
  float: left;
  text-align: right;
  min-width: 80px;
}

.totals {
  clear: both;
}

.hidden {
  display: none;
}

.form-group.image-upload {
  border-radius: 4px;
  border: 2px solid #adc6b8;
  padding: 1em;
}

.image-preview {
  width: 100%;
  border-radius: 4px;
  overflow: none;
  display: block;
}

.image-preview img {
  width: 100%;
  border: 1px solid #adc6b8;
  border-radius: 4px;
  min-height: 20px;
}

.tab-ui.merchant>.nav-pills {
  margin-bottom: 1em;
}

.tab-ui.store .nav-pills .nav-link {
  margin-right: 1em;
}

.tab-ui.store .nav-pills .nav-link:last-child {
  margin-right: 0;
}

.tab-ui .nav-pills .nav-link.merchant-nav-link {
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  background-color: unset;
  margin-right: 2em;
}

.tab-ui .nav-pills .nav-link {
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: #ccc;
}

.nav-pills .nav-link:hover {
  cursor: pointer;
}

.tab-ui .nav-pills .nav-link.active {
  background-color: #343a40;
  /*#094825;*/
}

.tab-content .tab-pane {
  display: none;
  padding: 0.5em;
  margin-bottom: 0.5em;
}

.tab-content .tab-pane.active {
  display: block;
}

.tab-pane .alert {
  clear: both;
}

.tab-content .store-tab-pane {
  border: 1px solid #ccc;
  background-color: #efefef;
}

.view-section {
  margin-bottom: 1em;
  /* border: 1px dashed #adc6b8;
  border-radius: 4px; */
  padding: 0.5em;
}

.view-section .view-section-label {
  font-weight: bold;
  margin-top: 0.5em;
}

.products-table .form-group,
.products-grid .form-group {
  margin: 0;
}

.products-table .form-group label,
.products-grid .form-group-label {
  display: none;
}

.btn-rightspace {
  margin-right: 1em;
}

.form-checkbox input.form-control,
.form-radio input.form-control {
  display: inline-block;
  width: 2em;
  vertical-align: middle;
}

.form-checkbox label,
.form-radio label {
  vertical-align: middle;
  margin: 0 0 0 0.2em;
}

.form-checkbox,
.form-radio {
  display: inline-block;
  margin-right: 2em;
}

.table.pb-table .form-group {
  margin: 0;
}

.table.pb-table td {
  vertical-align: middle;
}

.table.pb-table td button,
.table.pb-table td .btn.btn-sm {
  margin: 0.3em;
}

.table.pb-table .form-group label {
  margin: 0;
  float: right;
}

.floating-div {
  z-index: 1;
  position: sticky;
  left: 2em;
  right: 2em;
  top: 20px;
  padding: 2em;
  opacity: 80%;
  background-color: #efefef;
  border: 1px solid #333;
}

.hover-div {
  z-index: 1;
  position: fixed;
  left: 4px;
  right: 4px;
  top: 4px;
  padding: 2em;
  opacity: 90%;
  background-color: #efefef;
  border: 1px solid #CCC;
  height: 100%;
}

.row .col form {
  margin-bottom: 2em;
}

p.tall {
  min-height: 200px;
}

.centered {
  text-align: center;
}

.faq-qa {
  margin-top: 1em;
  margin-bottom: 1em;
}

.faq-qa .faq-question {
  font-weight: bold;
  font-style: italic;
}

.faq-qa table td:first {
  padding-right: 2em;
}

.faq-qa table th {
  padding: 0 4px 0 4px;
}

.faq-qa table td:first-child,
th:first-child {
  padding-right: 2em;
  text-align: left;
}

.faq-qa table td {
  text-align: right;
}

@media (max-width: 767px) {
  ul.navbar-nav {
    border: 1px solid #ccc;
  }

  ul.navbar-nav li.nav-link {
    padding-left: 1em;
  }
}

.checkboxes-ownership .form-checkboxes .form-checkbox {
  min-width: 120px;
}

.btn {
  font-size: 1.3em;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.85 !important;
}

.btn-success {
  color: #fff;
  background-color: #16723e;
  border-color: #16723e;
}

.splash {
  background-color: #DEFFE3;
  padding: 2em;
  border-radius: 0.4em;
  margin-bottom: 1em;
}

.topmar {
  margin-top: 1em;
}

.order-content {
  margin-bottom: 1em;
}

.cart-item-totals {
  min-height: 200px;
}

.checkout-links {
  margin-bottom: 1em;
}

.checkout-link {
  margin-right: 1em;
}

.urgent {
  background-color: #F99;
  padding: 1em;
  font-size: 1.1em;
}

.payment-block {
  border: 1px solid #efefef;
  background-color: #efefef;
  padding: 1em;
  margin-bottom: 1em;
}

#payment-message {
  background-color: #fe0;
}

.tagline {
  text-align: center;
  font-style: italic;
  font-size: 1.3em;
  color: #999;
  padding: 1em 1em 0 1em;
}

.fat-bottom {
  margin-bottom: 1em;
}

.extra-fat-bottom {
  margin-bottom: 2em;
}

.page-item:last-child .page-nolink {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-nolink {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.pagination .page-link {
  border-left: none;
  border-right: none;
}

.pagination .page-item:first-child .page-link {
  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination .page-item:last-child .page-link {
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

button[aria-expanded="true"] {
  background-color: #999 !important;
}

tr.warning {
  background-color: #FF9;
}

tr.error {
  background-color: #F99;
}

.landing1 {
  margin-top: 2em;
}

.landing1 p {
  padding: 0.4em 0 2em 0;
}

.landing1 h1 {
  text-align: center;
  margin-bottom: 1.4em;
}

.landing1 h2.fatty {
  font-size: 1.6rem;
}

.landing1 .fatty {
  font-size: 1.2rem;
  display: inline-block;
}

.topnav-landing1 {
  background-color: #094825;
  margin: 0 auto;
  padding: 1em;
}

.landing1 .row {
  margin-bottom: 1rem;
}

.topnav-landing1 .topnav-logo {
  width: 40%;
}

.topnav-landing1 a.navbar-brand .topnav-text {
  font-family: 'Damion', cursive;
  font-size: 3.2em;
  color: #42d07f;
  margin-left: 0.2em;
  vertical-align: middle;
}

.topnav-landing1 p {
  font-size: 2em;
  margin-left: 0.4em;
  color: #42d07f;
}

.topnav-landing1 a.navbar-brand div,
.topnav-landing1 a.navbar-brand img {
  display: inline-block;
  vertical-align: top;
}